import React from "react";
import backgroundImage from "../img/login.png";
import { Link} from "react-router-dom";
import image from "../img/shape.png";


const Signup= () => {
 

  return (
    <div className="relative h-screen">
    {/* Background Image */}
    <div
      className="absolute inset-0"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    ></div>
  
    {/* Signup Form Centered */}
    <div className="absolute inset-0 flex items-center justify-center">
      <div
        className="flex justify-center items-center"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "590px",
          height: "500px",
        }}
      >
        <div className="bg-white shadow-lg rounded-lg p-6 w-auto">
          <h2 className="text-2xl font-bold text-center">Signup</h2>
          <p className="text-black font-semibold text-center mb-4">
            for a BSG Admin Account
          </p>
  
        
          <form>
            <div className="mt-6 flex gap-4">
              <div className="flex-1">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter your name"
                />
              </div>
              <div className="flex-1">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter your email"
                />
              </div>
            </div>
  
            <div className="mt-4 flex gap-4">
              <div className="flex-1">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter your password"
                  />
                </div>
              </div>
            </div>
  
            <button
              type="submit"
              className="mt-6 w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600"
            >
              Signup
            </button>
          </form>
  
          <div className="mt-4 text-sm text-center">
            <p>
              Already have an account?{" "}
              <Link to="/" className="text-blue-500 hover:underline">
                Login here
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default Signup;
