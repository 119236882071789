import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import ProductList from "./components/AddQuestion";
import AddChapter from "./components/AddChapter";
import QuestionList from "./components/QuestionList";
import LoginPage from "./components/Login";
import Signup from "./components/Signup";

const App = () => {
  const location = useLocation(); 

  
  const hideSidebarRoutes = ["/"];

  return (
    <>
      {!hideSidebarRoutes.includes(location.pathname) && <Sidebar />}

      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/add-question" element={<ProductList />} />
        <Route path="/add-chapter" element={<AddChapter />} />
        <Route path="/question" element={<QuestionList />} />
      </Routes>
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
