import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { GET_ADD_CHAPTER, POST_ADD_QUESTION } from "../constant/constant";

const ProductList = () => {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [chapterName, setChapterName] = useState("");
  const [message, setMessage] = useState("");
  const [chapters, setChapters] = useState([]);
  useEffect(() => {
    const fetchChapters = async () => {
      try {
        const response = await axios.get(
          GET_ADD_CHAPTER
        );
        setChapters(response.data.data);
      } catch (error) {
       
      }
    };
    fetchChapters();
  }, []);
  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!chapterName || !question || !correctAnswer) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "All fields are required!",
      });
      return;
    }

    const newQuestion = {
      question,
      options,
      correctAnswer,
    };

    try {
      const response = await axios.post(
        POST_ADD_QUESTION,
        {
          chapterName,
          questions: [newQuestion],
        }
      );

      setMessage(response.data.message);
      setQuestion("");
      setOptions(["", "", "", ""]);
      setCorrectAnswer("");
      setChapterName("");

      Swal.fire({
        icon: "success",
        title: "Question Added!",
        text: response.data.message,
      });
    } catch (error) {
      setMessage("Failed to add the question.");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "An error occurred.",
      });
    }
  };

  return (
    <div className="ml-72">
      <h1 className="text-2xl font-semibold ml-4">Add Question</h1>
      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-md rounded px-8 py-6 max-w-lg mx-auto mt-10"
      >
        <div className="mb-5">
          <label
            htmlFor="chapterName"
            className="block text-sm font-medium text-gray-700"
          >
            Select Chapter
          </label>
          <select
            id="chapterName"
            value={chapterName}
            onChange={(e) => setChapterName(e.target.value)}
            className="mt-2 p-3 border border-gray-300 rounded w-full focus:ring-blue-500 focus:border-blue-500"
            required
          >
            <option value="">Select Chapter</option>
            {chapters.map((chapter) => (
              <option key={chapter._id} value={chapter.chapter}>
                {chapter.chapter}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-5">
          <label
            htmlFor="question"
            className="block text-sm font-medium text-gray-700"
          >
            Question
          </label>
          <input
            type="text"
            id="question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            className="mt-2 p-3 border border-gray-300 rounded w-full focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        {/* Options */}
        <div className="mb-5">
          <label className="block text-sm font-medium text-gray-700">
            Options
          </label>
          {options.map((option, index) => (
            <input
              key={index}
              type="text"
              value={option}
              onChange={(e) => handleOptionChange(index, e.target.value)}
              className="mt-2 p-3 border border-gray-300 rounded w-full focus:ring-blue-500 focus:border-blue-500 mb-3"
              placeholder={`Option ${index + 1}`}
              required
            />
          ))}
        </div>

        <div className="mb-5">
          <label
            htmlFor="correctAnswer"
            className="block text-sm font-medium text-gray-700"
          >
            Correct Answer
          </label>
          <select
            id="correctAnswer"
            value={correctAnswer}
            onChange={(e) => setCorrectAnswer(e.target.value)}
            className="mt-2 p-3 border border-gray-300 rounded w-full focus:ring-blue-500 focus:border-blue-500"
            required
          >
            <option value="">Select the correct answer</option>
            {options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-3 rounded hover:bg-blue-700 transition duration-200"
          >
            Add Question
          </button>
        </div>
      </form>

      {message && (
        <div className="mt-4">
          <p className="text-lg">{message}</p>
        </div>
      )}
    </div>
  );
};

export default ProductList;
