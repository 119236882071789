import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { POST_ADD_CHAPTER } from "../constant/constant";

const AddChapter = () => {
  const [chapter, setChapter] = useState("");
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    setChapter(e.target.value);
    setError("");
  };

  const validateInput = () => {
    const trimmedChapter = chapter.trim();
    if (!trimmedChapter) {
      return "Chapter name is required.";
    }
    if (trimmedChapter.length < 3) {
      return "Chapter name must be at least 3 characters.";
    }
    if (trimmedChapter.length > 50) {
      return "Chapter name cannot exceed 50 characters.";
    }
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationError = validateInput();
    if (validationError) {
      setError(validationError);
      Swal.fire({
        icon: "error",
     
        text: validationError,
      });
      return;
    }

    try {
      const response = await axios.post(
    POST_ADD_CHAPTER,
        { chapter: chapter.trim() }
      );

      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Chapter Created!",
          text: "The chapter has been added successfully.",
        });
        setChapter("");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Server error occurred",
      });
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-black dark:bg-black-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg dark:shadow-gray-700">
        <h2 className="text-center text-3xl font-semibold text-gray-900 dark:text-white">
          Add Chapter
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="chapter"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300"
            >
              Chapter Name
            </label>
            <input
              type="text"
              id="chapter"
              name="chapter"
              value={chapter}
              onChange={handleInputChange}
              className={`mt-2 block w-full px-4 py-2 border rounded-md shadow-sm focus:outline-none ${
                error
                  ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                  : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
              } dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-indigo-400 dark:focus:border-indigo-400`}
              placeholder="Enter chapter name"
            />
            {error && (
              <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                {error}
              </p>
            )}
          </div>

          <button
            type="submit"
            className="w-full mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300 dark:bg-blue-700 dark:hover:bg-blue-600"
          >
            Add Chapter
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddChapter;
